import { FormAnswerRadio, FormAnswerSelection, FormAnswerType } from '@eventlr/types/src/Frontend/event';
import { Checkbox, Field, Label } from '@headlessui/react';
import { useState } from 'react';

export type RadioFieldProps = {
  id: number;
  name: string;
  required: boolean;
  value: boolean | undefined;
  description?: string;
  errorMessage?: string | null | undefined;
  onChange: (val: {
    current: FormAnswerSelection & FormAnswerRadio;
    new: FormAnswerSelection & FormAnswerRadio;
  }) => void;
};

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(' ');
}

export default function RadioField(props: RadioFieldProps) {
  const [enabled, setEnabled] = useState(typeof props.value === 'boolean' ? props.value : false);

  function handleChange(state: boolean) {
    props.onChange({
      current: {
        type: FormAnswerType.RADIO,
        question_id: props.id,
        value: enabled,
      },
      new: {
        type: FormAnswerType.RADIO,
        question_id: props.id,
        value: state,
      },
    });
    setEnabled(state);
  }

  return (
    <div className="mb-4">
      <Field className="flex items-center gap-2">
        <Checkbox
          checked={enabled}
          onChange={handleChange}
          className={classNames(
            'group block size-4 rounded border bg-white data-[checked]:bg-blue-500',
            props.errorMessage ? 'border-red-500' : '',
          )}
        >
          <svg className="stroke-white opacity-0 group-data-[checked]:opacity-100" viewBox="0 0 14 14" fill="none">
            <path d="M3 8L6 11L11 3.5" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </Checkbox>
        <Label>
          {props.name}
          {props.required ? <span className="text-red-500"> *</span> : ''}
        </Label>
      </Field>
      {props.errorMessage ? (
        <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
          {props.errorMessage}
        </span>
      ) : null}
      {props.description && <div className="text-sm text-slate-500 mt-2">{props.description}</div>}
    </div>
  );
}

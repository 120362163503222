'use client';

import { CustomFlowbiteTheme, Modal } from 'flowbite-react';

import { useRouter } from 'next/navigation';
import { useEffect, useState } from 'react';

const BasicModal = ({
  openModal,
  dismissable = false,
  backLink,
  setheader,
  closeModalCallback,
  children,
  size = 'md',
}: {
  openModal: boolean;
  setheader?: string;
  dismissable?: boolean;
  backLink?: string;
  closeModalCallback?: () => void;
  children: React.ReactNode;
  size?: 'sm' | 'md' | 'lg';
}) => {
  const [open, setOpen] = useState(openModal);

  const router = useRouter();

  useEffect(() => {
    setOpen(openModal);
  }, [openModal]);

  const customTheme: CustomFlowbiteTheme['modal'] = {
    root: { base: 'fixed inset-x-0 top-0 z-50 h-screen overflow-y-auto overflow-x-hidden md:inset-0 md:h-full' },
    content: {
      base: 'relative w-full p-4',
    },
  };

  function onClose() {
    if (backLink) {
      router.push(backLink);
      return;
    }

    if (closeModalCallback) {
      closeModalCallback();
    }
  }

  return (
    <Modal show={open} dismissible={dismissable} size={size} onClose={onClose} popup theme={customTheme}>
      {setheader && <Modal.Header>{setheader}</Modal.Header>}
      <Modal.Body className="overflow-visible">{children}</Modal.Body>
    </Modal>
  );
};

export default BasicModal;

'use client';

import { USER_SESSION } from '@lib/user';
import React, { ReactNode, useState } from 'react';

interface StateContextType {
  user: USER_SESSION | null;
  loggedIn: boolean;
  authOpen: boolean;
  setAuthOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setLoggedIn: React.Dispatch<React.SetStateAction<boolean>>;
  setUser: React.Dispatch<React.SetStateAction<USER_SESSION | null>>;
}

type ContextProviderProps = {
  children?: ReactNode;
  initialLoggedIn?: boolean;
  authOpen?: boolean;
  user: USER_SESSION | null;
};

export const UserContext = React.createContext<StateContextType>({
  user: null,
  loggedIn: false,
  authOpen: false,
  setAuthOpen: () => {},
  setLoggedIn: () => {},
  setUser: () => {},
});

export function UserProvider({ initialLoggedIn, authOpen, children, user }: ContextProviderProps) {
  const [optimisticLoggedIn, setOptimisticLoggedIn] = useState<boolean>(initialLoggedIn ?? false);
  const [optimisticAuthOpen, setOptimisticAuthOpen] = useState<boolean>(authOpen ?? false);
  const [optimisticUser, setOptimisticUser] = useState<USER_SESSION | null>(user);

  return (
    <UserContext.Provider
      value={{
        loggedIn: optimisticLoggedIn,
        setLoggedIn: setOptimisticLoggedIn,
        authOpen: optimisticAuthOpen,
        setAuthOpen: setOptimisticAuthOpen,
        user: optimisticUser,
        setUser: setOptimisticUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export function useUser(): StateContextType {
  const context = React.useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }

  return context;
}

import { FormAnswerSelection, FormAnswerShortText, FormAnswerType } from '@eventlr/types/src/Frontend/event';
import { useState } from 'react';

export type TextFieldProps = {
  id: number;
  name: string;
  minLength: number;
  maxLength: number;
  required: boolean;
  value: string | undefined;
  description?: string;
  errorMessage?: string | null;
  onChange: (val: {
    current: FormAnswerSelection & FormAnswerShortText;
    new: FormAnswerSelection & FormAnswerShortText;
  }) => void;
};

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(' ');
}

export default function ShortTextField(props: TextFieldProps) {
  const [value, setValue] = useState(props.value ? props.value : '');

  function handleChange(event: { target: any }) {
    props.onChange({
      current: {
        type: FormAnswerType.SHORT_TEXT,
        question_id: props.id,
        value: value,
      },
      new: {
        type: FormAnswerType.SHORT_TEXT,
        question_id: props.id,
        value: event.target.value,
      },
    });
    setValue(event.target.value);
  }

  return (
    <div className="mb-4">
      <label htmlFor={props.id.toString()} className="block text-sm font-medium text-gray-700">
        {props.name}
        {props.required ? <span className="text-red-500"> *</span> : ''}
      </label>
      <div className="mt-1">
        <input
          type="text"
          required={props.required}
          key={props.id}
          name={props.id.toString()}
          id={props.id.toString()}
          className={classNames(
            'block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm',
            props.errorMessage ? 'border-red-500' : 'border-gray-300',
          )}
          onChange={handleChange}
          value={props.value}
        />
      </div>
      {props.errorMessage ? (
        <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
          {props.errorMessage}
        </span>
      ) : null}
      {props.description && <div className="text-sm text-slate-500 mt-2">{props.description}</div>}
    </div>
  );
}

import {
  FormAnswerSelect,
  FormAnswerSelection,
  FormAnswerType,
  FormQuestionOption,
} from '@eventlr/types/src/Frontend/event';
import { Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions, Label } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { useEffect, useState } from 'react';

export type SelectFieldOption = {
  id: number;
  name: string;
};

export type SelectFieldProps = {
  id: number;
  name: string;
  required: boolean;
  options: SelectFieldOption[];
  selected: number | undefined;
  description?: string;
  errorMessage?: string | null;
  onChange: (val: {
    current: FormAnswerSelection & FormAnswerSelect;
    new: FormAnswerSelection & FormAnswerSelect;
  }) => void;
};

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(' ');
}

function getSelected(selected: number, options: SelectFieldOption[]) {
  if (selected > 0) {
    const option = options.find((option) => option.id === selected);
    if (option) {
      return {
        id: option.id,
        name: option.name,
      };
    }
  }
  return null;
}

export default function SelectField(props: SelectFieldProps) {
  const [query, setQuery] = useState('');
  const [selected, setSelected] = useState<SelectFieldOption | null>(
    getSelected(props.selected && props.selected > 0 ? props.selected : 0, props.options),
  );
  const [value, setValue] = useState(props.selected && props.selected > 0 ? props.selected : 0);

  useEffect(() => {
    console.log('selected', selected);
    props.onChange({
      current: {
        type: FormAnswerType.SELECT,
        question_id: props.id,
        value: value,
      },
      new: { type: FormAnswerType.SELECT, question_id: props.id, value: selected?.id ?? 0 },
    });
    setValue(selected?.id ? selected.id : 0);
  }, [selected, value, props]);

  const options = props.required ? props.options : [{ id: 0, name: 'ei mitään' }, ...props.options];

  const filteredOptions =
    query === ''
      ? options
      : options.filter((option) => {
          return option.name.toLowerCase().includes(query.toLowerCase());
        });

  return (
    <Combobox as="div" className="mb-4" value={selected} onChange={setSelected}>
      <Label className="block text-sm font-medium text-gray-700">
        {props.name}
        {props.required ? <span className="text-red-500"> *</span> : ''}
      </Label>
      <div className="relative mt-1">
        <ComboboxInput
          className={classNames(
            'w-full rounded-md border bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm',
            props.errorMessage ? 'border-red-500' : 'border-gray-300',
          )}
          onChange={(event) => setQuery(event.target.value)}
          placeholder="Valitse vaihtoehto listasta"
          key={props.id}
          displayValue={(option: FormQuestionOption) => option?.name}
        />
        <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </ComboboxButton>

        {filteredOptions.length > 0 && (
          <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredOptions.map((option) => (
              <ComboboxOption
                key={option.id}
                value={option}
                className={({ focus }) =>
                  classNames(
                    'relative cursor-default select-none py-2 pl-3 pr-9',
                    focus ? 'bg-indigo-600 text-white' : 'text-gray-900',
                  )
                }
              >
                {({ focus, selected }) => (
                  <>
                    <span className={classNames('block truncate', selected && 'font-semibold')}>{option.name}</span>

                    {selected && (
                      <span
                        className={classNames(
                          'absolute inset-y-0 right-0 flex items-center pr-4',
                          focus ? 'text-white' : 'text-indigo-600',
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </ComboboxOption>
            ))}
          </ComboboxOptions>
        )}
      </div>
      {props.errorMessage ? (
        <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
          {props.errorMessage}
        </span>
      ) : null}
      {props.description && <div className="text-sm text-slate-500 mt-2">{props.description}</div>}
    </Combobox>
  );
}

import { FormAnswerSelection, FormAnswerTag, FormAnswerType, FormQuestionTag } from '@eventlr/types/src/Frontend/event';
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Field,
  Label,
} from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { useEffect, useState } from 'react';

export type TagFieldOption = {
  id: number;
  name: string;
};

export type TagFieldProps = {
  id: number;
  name: string;
  required: boolean;
  tags: TagFieldOption[];
  selected: number | undefined;
  description?: string;
  errorMessage?: string | null;
  onChange: (val: { current: FormAnswerSelection & FormAnswerTag; new: FormAnswerSelection & FormAnswerTag }) => void;
};

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(' ');
}

function getSelected(selected: number, tags: TagFieldOption[]) {
  if (selected > 0) {
    const tag = tags.find((tag) => tag.id === selected);
    if (tag) {
      return {
        id: tag.id,
        name: tag.name,
      };
    }
  }
  return null;
}

export default function TagField(props: TagFieldProps) {
  const [query, setQuery] = useState('');
  const [selected, setSelected] = useState<TagFieldOption | null>(
    getSelected(props.selected && props.selected > 0 ? props.selected : 0, props.tags),
  );
  const [value, setValue] = useState(props.selected && props.selected > 0 ? props.selected : 0);

  useEffect(() => {
    console.log('selected', selected, value, props);
    props.onChange({
      current: {
        type: FormAnswerType.TAG,
        question_id: props.id,
        value: value,
      },
      new: { type: FormAnswerType.TAG, question_id: props.id, value: selected?.id ?? 0 },
    });
    setValue(selected?.id ? selected.id : 0);
  }, [selected, value, props]);

  const tags = props.required ? props.tags : [{ id: 0, name: 'ei mitään' }, ...props.tags];
  const filteredTags =
    query === ''
      ? tags
      : tags.filter((tag) => {
          return tag.name.toLowerCase().includes(query.toLowerCase());
        });

  return (
    <Field className="mb-4">
      <Label className="text-sm font-medium text-gray-700">
        {props.name}
        {props.required ? <span className="text-red-500"> *</span> : ''}
      </Label>
      <Combobox as="div" value={selected} onChange={setSelected}>
        <div className="relative mt-1">
          <ComboboxInput
            className={classNames(
              'w-full rounded-md border bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm',
              props.errorMessage ? 'border-red-500' : 'border-gray-300',
            )}
            onChange={(event) => setQuery(event.target.value)}
            placeholder="Valitse vaihtoehto listasta"
            key={props.id}
            displayValue={(tag: FormQuestionTag) => tag?.name}
          />
          <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
            <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </ComboboxButton>

          {filteredTags.length > 0 && (
            <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {filteredTags.map((tag) => (
                <ComboboxOption
                  key={tag.id}
                  value={tag}
                  className={({ focus }) =>
                    classNames(
                      'relative cursor-default select-none py-2 pl-3 pr-9',
                      focus ? 'bg-indigo-600 text-white' : 'text-gray-900',
                    )
                  }
                >
                  {({ focus, selected }) => (
                    <>
                      <span className={classNames('block truncate', selected && 'font-semibold')}>{tag.name}</span>

                      {selected && (
                        <span
                          className={classNames(
                            'absolute inset-y-0 right-0 flex items-center pr-4',
                            focus ? 'text-white' : 'text-indigo-600',
                          )}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      )}
                    </>
                  )}
                </ComboboxOption>
              ))}
            </ComboboxOptions>
          )}
        </div>
        {props.errorMessage ? (
          <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
            {props.errorMessage}
          </span>
        ) : null}
        {props.description && <div className="text-sm text-slate-500 mt-2">{props.description}</div>}
      </Combobox>
    </Field>
  );
}
